
body {
  font-family: 'Helvetica-Black-SemiBold', sans-serif;
  color: #EEEEEE !important;
  background-color: #1E2022 !important;
}

h1 {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 600 ;
  text-align: center;
  font-size:6rem ;
}

h2 {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 600 !important;
  text-align: center;
  font-size:max(3rem, 20px);
}

ul {
  display: table;
  margin: 0 auto;
}