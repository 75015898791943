/* Banner styling */
#current .banner {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 800px ) {
    #current .banner > img {
        width: 60vw !important;
    }
}
#current .banner > img {
    display: flex;
    width: 90vw;
    height: auto;
}

/* Carousel styling */
@media (max-width: 600px) {
    #current .carousels {
        margin-bottom: 10px;
    }
}
#current .carousels {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    margin-top: 10px;
}

@media (min-width: 989px) {
    #current .carousel-column {
        margin-right: 20px;
    }
}

#current .carousel-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#current .carousel-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Carousel fixed dimension to address resizing mid-transition bug: https://stackoverflow.com/questions/17357306/bootstrap-carousel-resizing-image*/
/* Phone-only custom css: https://www.smashingmagazine.com/2010/07/how-to-use-css3-media-queries-to-create-a-mobile-version-of-your-website/*/

/* Border Gradient: https://www.digitalocean.com/community/tutorials/css-gradient-borders-pure-css */
@media (max-width: 340px) {
    #current .venue-carousel {
        width: 280px !important;
        height: 180px !important;
        margin-bottom: 15px !important;
    }

    #current .venue-item {
        width: 280px !important;
        height: 180px !important;
    }

    #current .personality-carousel {
        width: 280px !important;
        height: 300px !important;
    }

    #current .personality-item {
        width: 280px !important;
        height: 300px !important;
    }
}

@media (min-width: 340px) and (max-width: 470px) {
    #current .venue-carousel {
        width: 330px !important;
        height: 210px !important;
        margin-bottom: 15px !important;
    }

    #current .venue-item {
        width: 330px !important;
        height: 210px !important;
    }

    #current .personality-carousel {
        width: 330px !important;
        height: 350px !important;
    }

    #current .personality-item {
        width: 330px !important;
        height: 350px !important;
    }
}

@media (min-width: 600px) {
    #current .venue-carousel {
        width: 550px !important;
        height: 300px !important;
        margin-bottom: 45px !important;
    }

    #current .venue-item {
        width: 550px !important;
        height: 300px !important;
    }

    #current .personality-carousel {
        width: 500px !important;
        height: 600px !important;
    }

    #current .personality-item {
        width: 500px !important;
        height: 600px !important;
    }
}



#current .venue-carousel {
    display: flex;
    width: 440px;
    height: 330px;
    justify-content: center;
    border-style: solid;
    border-width: thick;
    border-image: linear-gradient(45deg, rgb(69,48,133), rgb(88,37,115)) 1;
    margin-bottom: 25px;
}

#current .venue-item {
    object-fit: fill;
    width: 440px;
    height: 330px;
}

#current .personality-carousel {
    display: flex;
    width: 440px;
    height: 460px;
    justify-content: center;
    border-style: solid;
    border-width: thick;
    border-image: linear-gradient(45deg, rgb(69,48,133), rgb(88,37,115)) 1;
}

#current .personality-item {
    object-fit: fill;
    width: 440px;
    height: 460px;
}

#current .carousel-caption {
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 12px;
}

/* Background opacity hack: https://www.geeksforgeeks.org/set-the-opacity-only-to-background-color-not-on-the-text-in-css/*/
#current .carousel-caption > p {
    background: rgba(30, 32, 34, 0.65);
}

@media (max-width: 988px) {
    #current .personality-carousel-label {
        margin-top: 10px;
    }
}

@media (min-width: 600px) {
    #current .carousel-label {
        font-size: 20px !important;
    }
}
#current .carousel-label {
    margin-bottom: 5px;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
}

/* Carousel transition speed: https://stackoverflow.com/questions/48665392/change-bootstrap-carousel-fade-transition */
/* Above doesn't work, but was basis for solution*/

#current .carousel > .carousel-inner > .carousel-item {
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    transition-property: opacity;
}

/* Carousel indicator styling */
/* Max-width based on children: https://stackoverflow.com/questions/27382777/set-parent-width-equal-to-children-total-width-using-only-css*/
#current .carousel > .carousel-indicators {
    background-color: rgba(30, 32, 34, 0.4);
    max-width: fit-content;
    padding: 6px 4px 0 4px;
    left: unset;
    right: unset;
}

@media (min-width: 600px) {
    #current .carousel > .carousel-indicators  > button{
        padding: 6px 6px !important;
    }
}
#current .carousel > .carousel-indicators > button {
    background-color: #98875c;
    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 5px;
    border-style: none inset inset none;
    border-width: medium;
    border-color: rgba(30, 32, 34, 0.3);
    border-radius: 100%;
    padding: 4px 4px;
}

#current .carousel > .carousel-indicators > .active {
    background-color: #592573;
}

/* Youtube embed styling */
@media (max-width: 340px) {
    #current .trailer {
        width: 280px !important;
        height: 180px !important;
    }
}

@media (min-width: 340px) and (max-width: 470px) {
    #current .trailer {
        width: 330px !important;
        height: 220px !important;
    }
}

@media (min-width: 600px) {
    #current .trailer {
        width: 550px !important;
        height: 300px !important;
    }
}
#current .trailer {
    width: 440px;
    height: 310px;
    border-style: solid;
    border-width: thick;
    border-image: linear-gradient(45deg, rgb(69,48,133), rgb(88,37,115)) 1;
}
/* Description/Sponsors Styling */
@media (min-width: 800px) {
    #current .text-information {
        background-image: url("../media/tournaments/2022/UWLogo.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size:300px 300px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

@media (max-width: 600px) {
    #current .text-information {
        margin-bottom: 15px;
    }
}
#current .text-information {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: 340px) {
    #current .text-information > div {
        min-width: 260px !important;
    }

    #current h2 {
        font-size: 16px !important;
    }

    #current p {
        font-size: 12px !important;
    }
    #current li {
        font-size: 12px !important;
    }
}

@media (min-width: 340px) and (max-width: 470px) {
    #current .text-information > div {
        min-width: 330px !important;
    }

    #current h2 {
        font-size: 18px !important;
    }
}

@media (min-width: 600px) {
    #current .text-information > div {
        text-align: left !important;
        width: 40% !important;
        margin: 10px !important;
    }

    #current h2 {
        font-size: 23px !important;
    }

    #current p {
        font-size: 16px !important;
    }
    #current li {
        font-size: 16px !important;
    }
}
#current .text-information > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 85%;
    min-width: 440px;
    height: auto;
    background: rgba(30, 32, 34, 0.87);
    border-radius: 15px;
}

#current h2 {
    font-size: 20px;
}

#current p {
    font-size: 14px;
}
#current li {
    font-size: 14px;
}

#current .sponsors > ul {
    padding-right: 25px;
}