/* Logo Background Styling */
.tournament-20xx {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (min-width: 968px) {
    .tournament-20xx {
        background-image: url("../media/tournaments/logoBackground.png");
        padding-top: 40px;
        background-position: top;
        background-repeat: no-repeat;
        background-size:570px 460px;
    }
}
/* Carousel styling */
.tournament-20xx .carousels {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/*margin: 7px max(8.3vw, 10px) 5px max(8.3vw, 10px);*/
.tournament-20xx .carousels > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Carousel fixed dimension to address resizing mid-transition bug: https://stackoverflow.com/questions/17357306/bootstrap-carousel-resizing-image*/
.tournament-20xx .carousel-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 340px) {
    .tournament-20xx .carousel {
        width: 280px !important;
        height: 200px !important;
    }

    .tournament-20xx .carousel-item > img {
        width: 280px !important;
        height: 200px !important;
    }
}

@media (min-width: 340px) and (max-width: 470px) {
    .tournament-20xx .carousel {
        width: 330px !important;
        height: 270px !important;
    }

    .tournament-20xx .carousel-item > img {
        width: 330px !important;
        height: 270px !important;
    }
}

@media (min-width: 600px) {
    .tournament-20xx .carousel {
        width: 380px !important;
        height: 270px !important;
    }

    .tournament-20xx .carousel-item > img {
        width: 380px !important;
        height: 270px !important;
    }
}

.tournament-20xx .carousel {
    margin: 25px 20px 0 20px;
    display: flex;
    width: 440px;
    height: 335px;
    justify-content: center;
    border-style: solid;
    border-width: thick;
    border-color: rgba(238, 238, 238, 0.9);
}

.tournament-20xx .carousel-item > img {
    object-fit: fill !important;
    width: 440px;
    height: 335px;
}

@media (min-width: 600px) {
    .tournament-20xx .carousel-caption {
        font-size: 13px !important;
    }
}

.tournament-20xx .carousel-caption {
    font-size: 12px;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 25px;
}

/* Background opacity hack: https://www.geeksforgeeks.org/set-the-opacity-only-to-background-color-not-on-the-text-in-css/*/
.tournament-20xx .carousel-caption > p {
    background: rgba(30, 32, 34, 0.65);
}
@media (max-width: 340px) {
    .tournament-20xx .carousel-label {
        width: 280px !important;
    }
}

@media (min-width: 340px) and (max-width: 470px) {
    .tournament-20xx .carousel-label {
        width: 330px !important;
    }
}

@media (min-width: 600px) {
    .tournament-20xx .carousel-label {
        width: 380px !important;
        font-size: 13px !important;
    }
}


.tournament-20xx .carousel-label {
    width: 440px;
    text-align: center;
    margin-top: 5px;
    font-size: 12px;
}

/* Carousel transition speed: https://stackoverflow.com/questions/48665392/change-bootstrap-carousel-fade-transition */
/* Above doesn't work, but was basis for solution*/

.tournament-20xx .carousel > .carousel-inner > .carousel-item {
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    transition-property: opacity;
}

/* Carousel indicator styling */
/* Max-width based on children: https://stackoverflow.com/questions/27382777/set-parent-width-equal-to-children-total-width-using-only-css*/
.tournament-20xx .carousel > .carousel-indicators {
    background-color: rgba(30, 32, 34, 0.4);
    max-width: fit-content;
    padding: 4px 4px 0 4px;
    left: unset;
    right: unset;
}

@media (min-width: 600px) {
    .tournament-20xx .carousel > .carousel-indicators > button {
        padding: 6px 6px !important;
    }
}
.tournament-20xx .carousel > .carousel-indicators > button {
    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 5px;
    border-radius: 100%;
    border-style: none inset inset none;
    border-width: medium;
    border-color: rgba(30, 32, 34, 0.3);
    background-color: rgba(238, 238, 238, 0.5);
    padding: 4px 4px;
}

.tournament-20xx .carousel > .carousel-indicators > .active {
    background-color: rgba(238, 238, 238, 0.9);
}

/* Tile Styling */
@media (min-width: 1320px) {
    .tournament-20xx .tiles {
        width: max(23vw, 150px) !important;
        height: max(10vw, 70px) !important;
    }
}
.tournament-20xx .tiles {
    align-self: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

@media (min-width: 1320px) {
    .tournament-20xx .tile {
        border-style: none inset inset none !important;
        border-width: medium !important;
        border-color: rgba(30, 32, 34, 0.8) !important;
    }
}
.tournament-20xx .tile {
    background-color: rgba(30, 32, 34, 0.6);
    border-width: thin;
    border-radius: 5%;
    border-style: solid;
    border-color: #EEEEEE;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    margin: 8px 8px;
    min-width: max(10vw, 130px);
}
@media (max-width: 340px) {
    .tournament-20xx .tile-value {
        font-size: 20px !important;
    }

    .tournament-20xx .tile-label {
        font-size: 12px !important;
    }

    .tournament-20xx h2 {
        font-size: 14px !important;
    }

    .tournament-20xx p {
        font-size: 12px !important;
    }

    .tournament-20xx li {
        font-size: 12px !important;
    }
}

@media (min-width: 340px) and (max-width: 470px) {
    .tournament-20xx .tile-value {
        font-size: 27px !important;
    }

    .tournament-20xx .tile-label {
        font-size: 14px !important;
    }

    .tournament-20xx h2 {
        font-size: 17px !important;
    }

    .tournament-20xx p {
        font-size: 14px !important;
    }

    .tournament-20xx li {
        font-size: 14px !important;
    }
}

@media (min-width: 600px) {
    .tournament-20xx .tile-value {
        font-size: 34px !important;
    }

    .tournament-20xx .tile-label {
        font-size: 16px !important;
    }

    .tournament-20xx h2 {
        font-size: 20px !important;
    }

    .tournament-20xx p {
        font-size: 16px !important;
    }

    .tournament-20xx li {
        font-size: 16px !important;
    }
}



.tournament-20xx .tile-value {
    color: #fa61c3;
    font-size: 34px;
}

.tournament-20xx .tile-label {
    font-size: 16px;
}

/* Description/Sponsors Styling */
.tournament-20xx h2 {
    font-size: 18px;
}

.tournament-20xx p {
    font-size: 15px;
}

.tournament-20xx li {
    font-size: 15px;
}

@media (max-width: 600px) {
    .tournament-20xx .text-information {
        margin-bottom: 15px;
    }
}
.tournament-20xx .text-information {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

@media (min-width: 600px) {
    .tournament-20xx .text-information > div {
        width: 35% !important;
        margin: 10px;
    }
}

@media (max-width: 340px) {
    .tournament-20xx .text-information > div {
        min-width: 260px !important;
    }
}
.tournament-20xx .text-information > div {
    display: flex;
    flex-direction: column;
    min-width: 330px;
    height: auto;
}

.tournament-20xx .sponsors > ul {
    padding-right: 25px;
}

@media (min-width: 1320px) {
    .tournament-20xx .description {
        margin-right: max(9vw, 75px) !important;
    }

    .tournament-20xx .sponsors {
        margin-left: max(9vw, 75px) !important;
    }
}
