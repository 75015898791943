/* Background image code: https://stackoverflow.com/questions/2662039/keep-background-image-fixed-during-scroll-using-css*/
/* Background image opacity hack: https://coder-coder.com/background-image-opacity/ */
/* Could not make background sticky */
#home {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#home:before {
    content: "";
    position: absolute;
    opacity: 60%;
    background-image: url("../media/logo.png");
    min-height: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}


#home .title {
    position: relative;
    width: max(95%, 260px);
    height: auto;
}

/* Facilitate positioning of text over cover image*/
@media (max-width: 320px) {
    #home .cover {
        width: 280px !important;
        height: 220px !important;
    }
}

@media (min-width: 820px) {
    #home .cover {
        width: 800px !important;
        height: 600px !important;
        min-height: 600px !important;
    }
}

#home .cover {
    display: flex;
    position: relative;
    width: 90%;
    min-width: 280px;
    min-height: max(48vw, 220px);
    border-radius: 15px;
    border-style: solid;
    border-width: thick;
    border-color: #EEEEEE;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Cover image styling*/
#home .cover-image {
    border-radius: 15px;
    position: absolute;
    width: 100%;
    height: 100%;
}

/* Styling for background of What is Intercollegiate? and its description */
@media (min-width: 800px) {
    #home .cover-description {
        position: absolute !important;
        width: 100% !important;
        height: 150px !important;
        border-radius: 0 0 15px 15px !important;
        margin-bottom: 0 !important;
        bottom: 0 !important;
        border-style: unset !important;
    }
}
#home .cover-description {
    min-width: max(90%, 260px);
    position: relative;
    background: rgba(30, 32, 34, 0.85);
    margin-bottom: 8px;
    border-radius: 15px;
    border-style: solid;
    border-width: thin;
    border-color: #EEEEEE;

}
/* What is Intercollegiate Yu-Gi-Oh?*/
@media (min-width: 800px) {
    #home h2 {
        font-size: 20px !important;
        margin-top: 0;
    }
}
#home h2 {
    position: relative;
    text-decoration: underline;
    width: 90%;
    min-width: 260px;
    top: 0;
    font-size: 14px;
    padding: 3px 0 3px 0;
    margin-top: 3px;
    margin-bottom: 0;
}

/* Description for "What is Intercollegiate Yu-Gi-Oh?" */
@media (min-width: 800px) {
    #home .description {
        font-size: 16px !important;
    }
}
#home .description {
    position: relative;
    font-size: 12px;
    width: 100%;
    min-width: 260px;
    height: 25%;
    padding: 7px;
}

/* Interactive Map*/
#map {
    position: relative;
    /*display: flex;*/
    width: min(80%, 800px);
    height: auto;
}

#map > svg {
    padding-left: 1.5vw;
    padding-top: 1.5vw;
    background: rgba(30, 32, 34, 0.85);
    width: auto;
    height: auto;
}

#map > svg > g {
    /*transform: translateX(-25%);*/
}

/* Discord Invite Link */
@media (min-width: 800px) {
    #home .invite-link {
        font-size: 16px !important;
    }
}
#home .invite-link {
    position: relative;
    background: black;
    font-size: 12px;
    font-weight: 600;
    padding: 5px 9px 0 9px;
    margin-top: 10px;

    border-radius: 15px 15px 0 0;
}

/* Discord Invite QRCode */
@media (min-width: 800px) {
    #home .invite-QR {
        width: 18% !important;
    }
}
#home .invite-QR {
    position: relative;
    width: max(40vw, 160px);
    height: auto;
}